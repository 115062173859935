import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { LocalizedLink } from "gatsby-theme-i18n"
import { useIntl, FormattedMessage } from "react-intl"
import StringSimilarity from "string-similarity"
import errorImg from "../images/404.webp"

import SEO from "components/seo"
import Layout from "components/layout"

import "scss/404.scss"

const HookSEO = () => {
  const { formatMessage } = useIntl()

  return <SEO title={formatMessage({ id: "Page Not found" })} />
}

const PageNotFound = () => {
  const { pathname } = useLocation()
  const { allSitePage } = useStaticQuery(
    graphql`
      query {
        allSitePage(
          filter: { path: { nin: ["/dev-404-page", "/404", "/404.html"] } }
        ) {
          nodes {
            path
          }
        }
      }
    `
  )

  const {
    bestMatch: { rating, target },
  } = StringSimilarity.findBestMatch(
    pathname,
    allSitePage.nodes.map(({ path }) => path)
  )

  return (
    <Layout>
      <HookSEO />
      <div className="header-blue">
        <div className="page404 container">
          <div className="page404__content">
            <div className="page404__content--title">404</div>
            <h1 className="page404__content--subtitle">
              <FormattedMessage id="Page not found" />
            </h1>
            <p>
              <FormattedMessage id="Our apologies, but your request was unsuccessful." />
            </p>
            {rating > 0.7 ? (
              <h3 className="link-result">
                <LocalizedLink to={target}>{target}</LocalizedLink>
              </h3>
            ) : (
              <h4>
                <FormattedMessage id="Yep, you're lost. There's no place like home :D, press the button below, and you'll be there." />
              </h4>
            )}
            <LocalizedLink className="cta__pink" to="/">
              <FormattedMessage id="Back Home" />
            </LocalizedLink>
          </div>
          <div
            className="page404__image"
            style={{ backgroundImage: `url(${errorImg})` }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export default PageNotFound
